import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import RedemptionSolarEnergyCTA from "../../componentsTailwind/RedemptionSolarEnergyCTA/RedemptionSolarEnergyCTA";
import { theme } from "../../style/theme";
import GlobalStyle from "../../style/GlobalStyles";

// Dočasné řešení pro Sonata page (Microsite v3) - propojení Gatsby šablony do twig šablony přes iframe
// src/Application/AppBundle/Resources/views/components/fotovoltaika-cta-banner.html.twig
const RedemptionSolarEnergyBanner = () => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet
        htmlAttributes={{
          lang: "cs",
        }}
      />
      <GlobalStyle />
      <RedemptionSolarEnergyCTA />
    </ThemeProvider>
  );
};

export default RedemptionSolarEnergyBanner;
