import React from "react";
import { Link } from "gatsby";
import { Container, Flex, Wrapper, Box } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Button } from "../../style/Button";
import { Text } from "../../style/Text";
import { useRedemptionSolarEnergyCTAData } from "../../hooks/useRedemptionSolarEnergyCTAData";
import { MarkupToHtml } from "../../helpers/parse";

const RedemptionSolarEnergyCTA = () => {
  const { title, subtitle, text, ctaText, ctaLink } = useRedemptionSolarEnergyCTAData();

  return (
    <Wrapper position={"relative"} backgroundColor="green.dark" zIndex="0" py={0}>
      <Box
        position="absolute"
        left="0"
        top="0"
        bottom="0"
        right={[0, null, "35%"]}
        backgroundPosition={["center top", null, "right center"]}
        backgroundSize={["auto 340px", null, "auto 100%"]}
        backgroundRepeat={`no-repeat`}
        backgroundImage={[
          `url(${require("../../images/banner/desktop-solar-house-mobile.jpg").default})`,
          null,
          `url(${require("../../images/banner/desktop-solar-house.jpg").default})`,
        ]}
        zIndex="-1"
      />
      <Wrapper backgroundColor={"transparent"} py={[null, null, "70px"]} pt={["40px", null, "70px"]}>
        <Container className={"text-center text-white"} px={0}>
          <Flex flexDirection="column" justifyContent="space-between" minHeight={"350px"} width={1}>
            <Box className="w-full px-5" alignSelf={"center"}>
              <Heading
                position={["absolute", null, "unset"]}
                top={["40px", null, "unset"]}
                left={"20px"}
                right={"20px"}
                as="h2"
                fontSize={["26px", null, "36px", null]}
                lineHeight={["35px", null, "50px", null]}
                textAlign="center"
                fontWeight="700"
                color={"white.100"}
              >
                <MarkupToHtml>{title}</MarkupToHtml>
              </Heading>
            </Box>
            <Flex flexDirection="row" alignItems={"flex-end"} mt={["25px", undefined, "60px"]} mb={["25px", undefined, "50px"]}>
              <Box
                width={[1, 1, "40%"]}
                mt={["220px", undefined, 0]}
                className="px-5"
                background={"transparent"}
                style={{
                  marginLeft: "auto",
                }}
              >
                <Flex flexDirection="column" alignItems={["flex-start"]} width={1} mb={"25px"}>
                  <Flex flexDirection="row" alignItems={"center"} mb={"10px"}>
                    <Box mr={["20px"]}>
                      <img src={require("../../images/svg/fve-circle.svg").default} alt={"logo"} width={"75"} className="mb-0" />
                    </Box>
                    <Heading as="h3" fontSize={"22px"} lineHeight={"28px"} fontWeight="700" color={"white.100"}>
                      <MarkupToHtml>{subtitle}</MarkupToHtml>
                    </Heading>
                  </Flex>
                  <Text as={"p"} fontSize="15px" lineHeight={"24px"} fontWeight="400" textAlign="left">
                    <MarkupToHtml>{text}</MarkupToHtml>
                  </Text>
                  <Flex justifyContent={["center", undefined, "flex-start"]} width={1} mt={"30px"}>
                    <Button target="_parent" variant="primary" as="a" href={ctaLink} minWidth={["210px"]}>
                      <MarkupToHtml>{ctaText}</MarkupToHtml>
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>
    </Wrapper>
  );
};

export default RedemptionSolarEnergyCTA;
