import { useStaticQuery, graphql } from "gatsby";

const useRedemptionSolarEnergyCTAData = () => {
  const {
    mnd: {
      redemptionSolarEnergyCta: {
        data: {
          attributes: { title, subtitle, text, ctaText, ctaLink },
        },
      },
    },
  } = useStaticQuery(
    graphql`
      query useRedemptionSolarEnergyCtaData {
        mnd {
          redemptionSolarEnergyCta {
            data {
              attributes {
                title
                subtitle
                text
                ctaText
                ctaLink
              }
            }
          }
        }
      }
    `
  );

  return {
    title,
    subtitle,
    text,
    ctaText,
    ctaLink,
  };
};

export { useRedemptionSolarEnergyCTAData };
